import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import './styles/styles.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
// import { PersistGate } from 'redux-persist/integration/react'
// import { store, persistor } from "./core/store";
import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import jwt from "jsonwebtoken";
import {persistUserData, refreshUserToken } from './core/actions/auth';
import allReducer from './core/reducers';


var store = createStore(allReducer, applyMiddleware(thunk));

if (window.localStorage.token) {
    var data = jwt.decode(window.localStorage.token);
    store.dispatch(refreshUserToken(data))
    store.dispatch(persistUserData({user:data, token:window.localStorage.token}))
}

ReactDOM.render(
  <Provider store={store}>
    {/* <PersistGate persistor={persistor}> */}
      <App />
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
