export const newDashboardBasePath = '/dashboard-new'

// export const routes = [
// 	{
// 		id: 'R1',
// 		title: '',
// 		children: [
// 			{
// 				id: 'R11',
// 				title: 'Dashboard',
// 				href: `${newDashboardBasePath}`,
// 				icon: 'homepageicon',
// 			}
// 		]
// 	},
// 	{
// 		id: 'R2',
// 		title: 'Pages',
// 		children: [
// 			{
// 				id: 'R21',
// 				title: 'Locations',
// 				href: `${newDashboardBasePath}/locations`,
// 				group: "locations.read",
// 				icon: 'locationsicon',
// 			},
// 			{
// 				id: 'R22',
// 				title: 'Remote Access',
// 				href: `${newDashboardBasePath}/remoteaccess`,
// 				group: "remote.read",
// 				icon: 'remoteaccessicon',
// 			},
// 			{
// 				id: 'R23',
// 				title: 'Resources',
// 				href: `${newDashboardBasePath}/resources`,
// 				group: "resources.read",
// 				icon: 'reaourcessicon',
// 			},
// 			{
// 				id: 'R24',
// 				title: 'Security Group',
// 				href: `${newDashboardBasePath}/securitygroup`,
// 				group: "securityGroup.read",
// 				icon: 'securitygroupicon',
// 			},
// 			{
// 				id: 'R25',
// 				title: 'Sub Admin',
// 				href: `${newDashboardBasePath}/subadmin`,
// 				group: "subAdmin.read",
// 				icon: 'subadminicon',
// 			},
// 			{
// 				id: 'R26',
// 				title: 'Vendors',
// 				href: `${newDashboardBasePath}/vendors`,
// 				group: "vendors.read",
// 				icon: 'vendorsicon',
// 			},
// 			{
// 				id: 'R27',
// 				title: 'Digital Signage',
// 				href: '/#',
// 				group: "signage.read",
// 				icon: 'digitalsignage',
// 			},
// 			{
// 				id: 'R28',
// 				title: 'Logout',
// 				href: '/#',
// 				icon: 'logouticon',
// 			}
// 		]
// 	}
// ]



export const routes = [
	{
		id: 'R21',
		title: 'Locations',
		href: `${newDashboardBasePath}/locations`,
		group: "locations.read",
		icon: 'locationsicon',
	},
	{
		id: 'R22',
		title: 'Remote Access',
		href: `${newDashboardBasePath}/remoteaccess`,
		group: "remote.read",
		icon: 'remoteaccessicon',
	},
	{
		id: 'R23',
		title: 'Resources',
		href: `${newDashboardBasePath}/resources`,
		group: "resources.read",
		icon: 'reaourcessicon',
	},
	{
		id: 'checks',
		title: 'Checks',
		href: `${newDashboardBasePath}/checks`,
		group: "checks.read",
		icon: 'reaourcessicon',
	},
	{
		id: 'R24',
		title: 'Security Group',
		href: `${newDashboardBasePath}/securitygroup`,
		group: "securityGroup.read",
		icon: 'securitygroupicon',
	},
	{
		id: 'R25',
		title: 'Sub Admin',
		href: `${newDashboardBasePath}/subadmin`,
		group: "subAdmin.read",
		icon: 'subadminicon',
	},
	{
		id: 'R26',
		title: 'Vendors',
		href: `${newDashboardBasePath}/vendors`,
		group: "vendors.read",
		icon: 'vendorsicon',
	},
	{
		id: 'R27',
		title: 'Digital Signage',
		href: `${newDashboardBasePath}/digital_signage`,
		group: "signage.read",
		icon: 'digitalsignage',
	},
	
]

export const logOutRoute = [
	{
	
			id: 'R28',
			title: 'Logout',
			href: '/#',
			icon: 'logouticon',
		
	}
]

export const routers = [
{
		id: 'R1',
		title: '',
		children: [
			{
				id: 'R11',
				title: 'Dashboard',
				href: `${newDashboardBasePath}`,
				icon: 'homepageicon',
			}
		]
	},
	
]

export const pageRoute = [
	{
		id: 'R2',
		title: 'PAGES',
	}
]