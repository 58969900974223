import { Suspense , lazy, useState} from "react"
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
// import Home from "./pages/homepage"

import { newDashboardBasePath } from "./utils/routes"
import { LoadingOutlined } from "@ant-design/icons"
const Testimonials = lazy(()=> import ("./pages/testimonials"))
const Pricing = lazy(()=> import ("./pages/pricing"))
const Login  = lazy(()=> import ("./pages/login"))
const VendorDashboard  = lazy(()=> import ("./pages/VendorDashboard"))
const AdminDashboard  = lazy(()=> import ("./pages/AdminDashboard"))
const DashboardLayout  = lazy(()=> import ("./Layout/DashboardLayout"))
const Check  = lazy(()=> import ("./components/checks/check"))
const SignagePlaylist  = lazy(()=> import ("./pages/signage/index.js"))
const Signup  = lazy(()=> import ("./pages/signup" ))
const ScreenPlaylist  = lazy(()=> import ("./pages/signage/ScreenPlaylist"))
const Dashboardnew  = lazy(()=> import ('./pages/dashboardnew/dashboardnew'))
const Forgotpassword  = lazy(()=> import ("./pages/forgotpassword/index.jsx"))
const Upsell  = lazy(()=> import ("./pages/upsell/index.jsx"))
const Checkout  = lazy(()=> import ("./pages/checkout/index.jsx"))
const PaymentSuccessful  = lazy(()=> import ("./pages/paymentSuccessful/index.jsx"))
const Freetrial2 = lazy(()=> import ("./pages/freetrial/FreeTrial2.jsx"))
const Freetrial  = lazy(()=> import ("./pages/freetrial"))
const Landingpage  = lazy(()=> import ("./pages/landingpage/index.jsx"))
const BusinessInsights  = lazy(()=> import ("./pages/landingpage/BusinessInsights.jsx"))
const BusinessForm  = lazy(()=> import ("./pages/landingpage/Landingpage4.jsx"))
const Landingpage4  = lazy(()=> import ("./pages/landingpage/Landingpage3.jsx"))
const Landingpage6  = lazy(()=> import ("./pages/landingpage/Landingpage6.jsx"))
const PaymentWrapper  = lazy(()=> import ("./pages/landingpage/PaymentWrapper.jsx"))

function App() {
useState(()=>{
  if(document.referrer) window.localStorage.setItem('referrer', document.referrer)
}, [])
  
  return (
    <Suspense fallback={<Loader />}>
    <BrowserRouter>
      <Switch>
        {/* <Route exact path="/dashboard" component={Dashboard} /> */}
          <Route exact path="/" component={Landingpage} />
          <Route exact path="/upsell" component={Upsell} />
          <Route exact path="/payment/success" component={PaymentSuccessful} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/freetrial" component={Freetrial} />
          <Route exact path="/trial" component={Freetrial2} />
          <Route exact path="/insights" component={BusinessInsights} />
          <Route exact path="/animation-setup" component={BusinessForm} />
          <Route exact path="/thankyou" component={Landingpage4} />
          <Route exact path="/animated-checkout" component={PaymentWrapper} />
          <Route exact path="/animated-video" component={Landingpage6} />
          
        <Route exact path="/login" component={Login} />
				<Route exact path="/forgot-password" component={Forgotpassword} />
   
        <Route exact path="/signage/:slug" component={SignagePlaylist} />
        <Route exact path="/signage-screen/:slug" component={ScreenPlaylist} />
        <Route
            path={`/viewcheck`}
            exact
            render={(props) => <Check 
            {...props} 
            />}
          />
        <Route  path="/vendor/dashboard"
          component={(props) => <DashboardLayout {...props}>
            <VendorDashboard {...props} />
          </DashboardLayout>} />
          <Route  path="/admin/dashboard"
          component={(props) => <DashboardLayout {...props}>
            <AdminDashboard {...props} />
          </DashboardLayout>} />
 
          <Route path="/dashboard" component={(props) => <Dashboardnew {...props} /> } />
          <Route path={newDashboardBasePath} component={(props) => <Dashboardnew {...props} />} />
          <Route exact path="/testimonials" component={Testimonials} />
         
        <Route  path="/signup" component={Signup} />
        <Redirect path="*" to="/" />
      </Switch>
    </BrowserRouter>
    </Suspense>
  );
}
const Loader = () => <div style={{display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", width: "100%", height: "100%", fontSize: '2em'}}><LoadingOutlined  /></div>

export default App;
