import axios from "axios"
import api from "../../config/api";
import * as CONSTANTS from '../constants'
import jwt from "jsonwebtoken"


// --- PERSIST USER DATA FROM LOCALSTORAGE
export const persistUserData = payload => ({ type: CONSTANTS.PERSIST_USER_DATA, payload });

// --- REFRESH USER TOKEN FROM BACKEND
export const refreshUserToken = (user) => dispatch => {
  axios.get(api + "/api/users/refreshToken",
  {headers: {
    Authorization: window.localStorage.getItem("token"),
  }})
    .then((response) => {
      var { token , notifications} = response.data
      window.localStorage.setItem("notifications", JSON.stringify(notifications))
      var data = jwt.decode(token)
      window.localStorage.setItem('token', token)
    }).catch((err) => {
      window.localStorage.clear()
      window.location.assign('/login')

    })
}